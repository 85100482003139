import { getAnalytics, logEvent } from "firebase/analytics";
import { Menu, X } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ConsultantHamburger from './ConsultantHamburger';
import NotificationBell from "./NotificationBell";

const ConsultantRequests = () => {
  const [requests, setRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Consultant Requests',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUser();
    fetchRequests();
  }, []);

  const fetchUser = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      setUser(data);
    } catch (error) {
      console.error('Error fetching user:', error.message);
    }
  };

  const fetchRequests = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const { data, error } = await supabase
        .from('user_jobs')
        .select(`
          *,
          job:jobs!inner(
            job_id, 
            title,
            client:clients(name)
          ),
          interviews(interview_time, zoom_meeting_link)
        `)
        .eq('user_id', user.id)
        .in('status', ['Second Interview Requested', 'Contract Offered'])
        .order('last_updated_at', { ascending: false });
  
      if (error) throw error;
      setRequests(data);
    } catch (error) {
      console.error('Error fetching requests:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAccept = async (userJobId, slot = null) => {
    try {
      setIsLoading(true);
      const request = requests.find(r => r.user_job_id === userJobId);

      if (request.status === 'Second Interview Requested') {
        const slotUTC = new Date(slot).toUTCString();

        const { error: interviewError } = await supabase
          .from('interviews')
          .insert({
            user_job_id: userJobId,
            interview_time: slot,
            is_second_interview: true
          });

        if (interviewError) throw interviewError;

        const { error: userJobError } = await supabase
          .from('user_jobs')
          .update({ status: 'Second Interview Scheduled' })
          .eq('user_job_id', userJobId);

        if (userJobError) throw userJobError;

        alert('Interview scheduled successfully!');
      } else if (request.status === 'Contract Offered') {
        const now = new Date().toISOString();

        const { error: engagementError } = await supabase
        .from('engagements')
        .update({
          status: 'Contract Accepted',
          accepted_at: now
        })
        .eq('user_job_id', userJobId);

        if (engagementError) throw engagementError;

        const { error: userJobError } = await supabase
          .from('user_jobs')
          .update({ status: 'Contract Accepted' })
          .eq('user_job_id', userJobId);

        if (userJobError) throw userJobError;

        const { error: jobError } = await supabase
          .from('jobs')
          .update({ status: 'Filled' })
          .eq('job_id', request.job.job_id);

        if (jobError) throw jobError;

        alert('Contract accepted successfully!');
        navigate('/app/engagement-management');
        return;
      }

      fetchRequests();
    } catch (error) {
      console.error('Error accepting request:', error.message);
      alert('Failed to process your request. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDecline = async (userJobId) => {
    try {
      const request = requests.find(r => r.user_job_id === userJobId);
      const { error } = await supabase
        .from('user_jobs')
        .update({ 
          status: request.status === 'Second Interview Requested' ? 'Second Interview Declined' : 'Contract Declined'
        })
        .eq('user_job_id', userJobId);

      if (error) throw error;

      fetchRequests();
    } catch (error) {
      console.error('Error declining request:', error.message);
      alert('Failed to decline request. Please try again.');
    }
  };

  const formatLocalDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    return date.toLocaleString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short'
    });
  };

  return (
    <>
      <Helmet>
        <title>Consultant Requests | fetchConsultant</title>
        <meta name="description" content="Manage your interview requests and contract offers on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ConsultantHamburger user={user} currentPage="requests" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white p-4 flex justify-between items-center">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">Requests</h1>
            </div>
            <div className="flex items-center">
              <NotificationBell />
            </div>
          </header>
          <main className="flex-grow container mx-auto px-4 py-8">
            {isLoading ? (
              <p>Loading requests...</p>
            ) : requests.length === 0 ? (
              <p>No pending requests or offers.</p>
            ) : (
              <ul className="space-y-4">
                {requests.map((request) => (
                  <li key={request.user_job_id} className="bg-white p-6 rounded-lg shadow-md">
                    <h2 className="text-xl font-semibold mb-2">{request.job.title}</h2>
                    <p className="mb-2">Client: {request.job.client.name}</p>
                    <p className="mb-4">
                      {request.status === 'Second Interview Requested' ? 'Second Interview Request' : 'Contract Offer'}
                    </p>
                    {request.status === 'Second Interview Requested' && (
                      <div className="mb-4">
                        <h3 className="font-semibold mb-2">Proposed Interview Slots:</h3>
                        <ul className="space-y-2">
                          {request.second_interview_slots.map((slot, index) => (
                            <li 
                              key={index} 
                              className="p-2 bg-gray-100 rounded cursor-pointer hover:bg-gray-200"
                              onClick={() => handleAccept(request.user_job_id, slot)}
                            >
                              {formatLocalDateTime(slot)}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    {request.status === 'Contract Offered' && (
                      <div className="mb-4">
                        <h3 className="font-semibold mb-2">Contract Offer Details:</h3>
                        <p>Rate: ${request.consultant_rate}/hr</p>
                        <p>Start Date: {formatLocalDateTime(request.available_start_date)}</p>
                        <button
                          onClick={() => handleAccept(request.user_job_id)}
                          className="bg-green-500 text-white px-4 py-2 rounded mt-2 hover:bg-green-600"
                        >
                          Accept Offer
                        </button>
                      </div>
                    )}
                    <button
                      onClick={() => handleDecline(request.user_job_id)}
                      className="bg-red-500 text-white px-4 py-2 rounded flex items-center mt-2 hover:bg-red-600"
                    >
                      <X className="mr-2" size={20} />
                      Decline
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </main>
        </div>
      </div>
    </>
  );
};

export default ConsultantRequests;