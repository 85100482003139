import { formatToTimeZone } from 'date-fns-timezone';
import { getAnalytics, logEvent } from "firebase/analytics";
import { Menu, X } from 'lucide-react';
import { ArrowLongLeftIcon, HomeIcon } from '@heroicons/react/20/solid';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate, useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { createZoomMeeting } from '../utils/zoomIntegration';
import ClientHamburger from './ClientHamburger';
import NotificationBell from "./NotificationBell";
import { utcToZonedTime, format } from 'date-fns-tz';

const ScheduleInterview = () => {
  const [applicant, setApplicant] = useState(null);
  const [interviewSlots, setInterviewSlots] = useState([]);
  const [notification, setNotification] = useState({ message: '', type: '', show: false });
  const [isLoading, setIsLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [additionalInvitees, setAdditionalInvitees] = useState([{ email: '' }]);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const { userJobId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Schedule Interview',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchApplicantDetails();
  }, [userJobId]);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      const { data: { user }, error } = await supabase.auth.getUser();
      if (error) {
        console.error('Error fetching current user:', error);
        showNotification('Error fetching user information', 'error');
      } else {
        setCurrentUser(user);
      }
    };

    fetchCurrentUser();
  }, []);

  const fetchApplicantDetails = async () => {
    try {
      const { data, error } = await supabase
        .from('user_jobs')
        .select(`
          *,
          applicant:user_id (name, login_email),
          job:job_id (title, job_id)
        `)
        .eq('user_job_id', userJobId)
        .single();

      if (error) throw error;
      console.log('Fetched applicant data:', data);
      setApplicant(data);

      setInterviewSlots(data.consultant_slots || []);
    } catch (error) {
      console.error('Error fetching applicant details:', error);
    }
  };

  const handleSlotSelection = (slot) => {
    setSelectedSlot(slot);
    setIsConfirmationModalOpen(true);
  };

  const confirmSchedule = async () => {
    console.log('confirmSchedule triggered with slot:', selectedSlot);

    if (!currentUser) {
      console.error('No currentUser found');
      showNotification('User information not available', 'error');
      return;
    }

    setIsLoading(true);

    try {
      const slotDate = new Date(selectedSlot);
      console.log('Slot date (local):', slotDate);

      // Convert local time to UTC
      const utcDate = new Date(slotDate.toUTCString());
      const formattedUtcDate = format(utcDate, "yyyy-MM-dd'T'HH:mm:ss'Z'", { timeZone: 'UTC' });
      console.log('Slot date (UTC):', formattedUtcDate);

      // Convert for Zoom meeting
      const zoomUtcDate = slotDate.toISOString().split('.')[0];

      // Create Zoom meeting
      const meetingDetails = await createZoomMeeting(
        `Interview for ${applicant.job.title} with ${applicant.applicant.name}`,
        zoomUtcDate,
        60 // Assuming 60 minutes duration
      );
      console.log('Zoom meeting details:', meetingDetails);

      if (!meetingDetails || !meetingDetails.id || !meetingDetails.join_url) {
        throw new Error('Failed to create Zoom meeting. Please try again.');
      }

      // Generate iCalendar data
      const icsData = generateICS(slotDate, applicant, meetingDetails, currentUser, additionalInvitees);
      console.log('Generated ICS data:', icsData);

      // Insert new interview record
      const { data: interviewData, error: interviewError } = await supabase
        .from('interviews')
        .insert({
          user_job_id: userJobId,
          interview_time: slotDate.toISOString(),
          zoom_meeting_link: meetingDetails.join_url,
          zoom_meeting_id: meetingDetails.id.toString(),
          ics_data: icsData,
          additional_invitees: additionalInvitees
            .filter((invitee) => invitee.email.trim() !== '')
            .map((invitee) => invitee.email),
        })
        .select()
        .single();

      if (interviewError) throw interviewError;

      console.log('Inserted interview data:', interviewData);

      // Update user_jobs table
      const { error: userJobError } = await supabase
        .from('user_jobs')
        .update({
          status: 'Interview Scheduled',
        })
        .eq('user_job_id', userJobId);

      if (userJobError) throw userJobError;

      console.log('Updated user_jobs status to Interview Scheduled');

      // Log the event
      const analytics = getAnalytics();
      logEvent(analytics, 'interview_scheduled', {
        user_job_id: userJobId,
        interview_time: slotDate.toISOString(),
      });

      showNotification("Interview Scheduled! We've notified the applicant and sent Zoom invites.", 'success');

      // Redirect to the Applicants page after a short delay
      setTimeout(() => {
        navigate(`/app/applicants/${applicant.job.job_id}`);
      }, 3000);
    } catch (error) {
      console.error('Error scheduling interview:', error);
      showNotification(`Error scheduling interview: ${error.message}`, 'error');
    } finally {
      setIsLoading(false);
      setIsConfirmationModalOpen(false);
    }
  };

  // Function to generate ICS data
  const generateICS = (startDate, applicant, meetingDetails, currentUser, additionalInvitees) => {
    const endDate = new Date(startDate.getTime() + 60 * 60 * 1000); // 1 hour later
    const now = new Date();

    const formatDate = (date) => formatToTimeZone(date, 'YYYYMMDDTHHmmss', { timeZone: 'UTC' });

    const attendees = [
      applicant.applicant.login_email,
      currentUser.email,
      ...additionalInvitees.filter(invitee => invitee.email.trim() !== '').map(invitee => invitee.email)
    ].join(',');

    return `BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//fetchConsultant//Interview//EN
CALSCALE:GREGORIAN
METHOD:REQUEST
BEGIN:VEVENT
DTSTART:${formatDate(startDate)}Z
DTEND:${formatDate(endDate)}Z
DTSTAMP:${formatDate(now)}Z
ORGANIZER;CN=shane@fetchconsultant.com
UID:${Math.random().toString(36).substring(2)}@fetchconsultant.com
ATTENDEE;ROLE=REQ-PARTICIPANT;PARTSTAT=NEEDS-ACTION;RSVP=TRUE:${attendees}
SUMMARY:fC - Interview for ${applicant.job.title}
DESCRIPTION:Interview with ${applicant.applicant.name} for ${applicant.job.title}\\nZoom link: ${meetingDetails.join_url}
LOCATION:${meetingDetails.join_url}
URL:${meetingDetails.join_url}
STATUS:CONFIRMED
SEQUENCE:0
END:VEVENT
END:VCALENDAR`;
  };

  const showNotification = (message, type) => {
    setNotification({ message, type, show: true });
  };

  const closeNotification = () => {
    setNotification({ ...notification, show: false });
  };

  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) return null;
    const date = new Date(dateTimeString);
    return date.toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  const handleAddInvitee = () => {
    setAdditionalInvitees([...additionalInvitees, { email: '' }]);
  };

  const handleRemoveInvitee = (index) => {
    const newInvitees = additionalInvitees.filter((_, i) => i !== index);
    setAdditionalInvitees(newInvitees);
  };

  const handleInviteeChange = (index, value) => {
    const newInvitees = [...additionalInvitees];
    newInvitees[index].email = value;
    setAdditionalInvitees(newInvitees);
  };

  if (!currentUser) {
    return <div>Loading user information...</div>;
  }

  return (
    <>
      <Helmet>
        <title>Schedule Interview | fetchConsultant</title>
        <meta name="description" content="Schedule an interview with a consultant on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ClientHamburger isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 md:ml-64 flex flex-col">
          <header className="bg-white shadow-md p-4 flex justify-between items-center">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4">
                <Menu size={24} />
              </button>
              {/* Use isMenuOpen to conditionally render breadcrumb or back arrow */}
              {isMenuOpen ? (
                <div className="flex items-center cursor-pointer" onClick={() => navigate(-1)}>
                  <ArrowLongLeftIcon className="h-6 w-6 text-gray-500" />
                  <span className="text-gray-500">Back to Applicant Details</span>
                </div>
              ) : (
                <nav className="flex items-center text-sm space-x-2">
                  <HomeIcon className="h-4 w-4 text-gray-500 cursor-pointer" onClick={() => navigate('/app/client-dashboard')} aria-hidden="true" />
                  <span className="text-gray-400">/</span>
                  <span className="text-gray-500 cursor-pointer" onClick={() => navigate(-2)}>Applicants</span>
                  <span className="text-gray-400">/</span>
                  <span className="text-gray-500 cursor-pointer" onClick={() => navigate(-1)}>Applicant Details</span>
                  <span className="text-gray-400">/</span>
                  <span className="text-gray-500">{applicant?.applicant?.name}</span>
                </nav>
              )}
            </div>
            <div className="flex items-center">
              <NotificationBell />
            </div>
          </header>
          <main className="flex-1 overflow-y-auto p-4">
            <div className="fetch-card">
              <p className="text-left text-gray-600 mb-4">
                Below are the times {applicant?.applicant?.name} said they were available for an interview.
                Select an option below or propose alternative times if none of the options work.
              </p>

              <h2 className="fetch-label mb-2">Choose a time below to schedule the interview.</h2>
              {interviewSlots.map((slot, index) => (
                <button
                  key={index}
                  onClick={() => handleSlotSelection(slot)}
                  className="fetch-button mb-2 w-full text-left"
                  disabled={isLoading}
                >
                  Slot {index + 1}: {formatDateTime(slot)}
                </button>
              ))}

              {applicant?.consultant_general_availability && (
                <div className="mt-4">
                  <h2 className="fetch-label mb-2">Consultant's General Availability</h2>
                  <ReactQuill
                    value={applicant.consultant_general_availability}
                    readOnly={true}
                    theme="bubble"
                  />
                </div>
              )}

              <button
                onClick={() => navigate(`/app/client-propose-alternative-times/${userJobId}`)}
                className="fetch-button mt-4 w-full"
                disabled={isLoading}
              >
                Propose Alternative Times
              </button>

              {isLoading && <p>Scheduling interview...</p>}
            </div>
          </main>

          {/* Notification */}
          {notification.show && (
            <div className={`notification notification-${notification.type}`}>
              <div className="flex-1">
                <p className="font-medium">{notification.message}</p>
              </div>
              <button onClick={closeNotification} className="ml-4">
                <X className="w-5 h-5" />
              </button>
            </div>
          )}

          {/* Confirmation Modal */}
          {isConfirmationModalOpen && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white rounded-lg p-6 w-full max-w-md">
                <h2 className="text-xl font-semibold mb-4">Confirm Interview Schedule</h2>
                <p className="mb-4">
                  Are you sure you want to schedule the interview at{' '}
                  <strong>{formatDateTime(selectedSlot)}</strong>?
                </p>

                {/* Additional Invitees fields */}
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Additional Invitees</label>
                  {additionalInvitees.map((invitee, index) => (
                    <div key={index} className="flex items-center mt-2">
                      <input
                        type="email"
                        value={invitee.email}
                        onChange={(e) => handleInviteeChange(index, e.target.value)}
                        className="fetch-input flex-grow mr-2"
                        placeholder="Email address"
                      />
                      {index > 0 && (
                        <button
                          type="button"
                          onClick={() => handleRemoveInvitee(index)}
                          className="text-red-600"
                        >
                          Remove
                        </button>
                      )}
                    </div>
                  ))}
                  <button
                    type="button"
                    onClick={handleAddInvitee}
                    className="mt-2 text-sm text-indigo-600 hover:text-indigo-500"
                  >
                    Add Another Invitee
                  </button>
                </div>

                <div className="flex justify-end">
                  <button
                    onClick={() => setIsConfirmationModalOpen(false)}
                    className="fetch-button mr-2"
                    disabled={isLoading}
                  >
                    Cancel
                  </button>
                  <button onClick={confirmSchedule} className="fetch-button" disabled={isLoading}>
                    {isLoading ? 'Scheduling...' : 'Confirm'}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ScheduleInterview;