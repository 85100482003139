import { getAnalytics, logEvent } from "firebase/analytics";
import { ChevronLeft, Menu, Plus, X } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Helmet } from 'react-helmet';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ConsultantHamburger from './ConsultantHamburger';
import NotificationBell from './NotificationBell';

const ApplyJobInterview = () => {
  const [job, setJob] = useState(null);
  const [interviewSlots, setInterviewSlots] = useState(['']);
  const [generalAvailability, setGeneralAvailability] = useState('');
  const [formValid, setFormValid] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [user, setUser] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const { jobId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const utcToLocal = (utcDate) => {
    const date = new Date(utcDate);
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  };

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Apply for Job - Interview',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchJobDetails();
    fetchUser();

    const savedData = localStorage.getItem(`applyJobInterviewData_${jobId}`);
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setInterviewSlots(parsedData.interviewSlots.map(slot => slot ? new Date(slot) : ''));
      setGeneralAvailability(parsedData.generalAvailability || '');
      setTermsAccepted(parsedData.termsAccepted || false);
    } else {
      fetchExistingApplication();
    }

    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('termsAccepted') === 'true') {
      setTermsAccepted(true);
    }

    return () => {
      localStorage.removeItem(`applyJobInterviewData_${jobId}`);
    };
  }, [jobId, location.search]);

  useEffect(() => {
    const validSlots = interviewSlots.filter(slot => slot !== '').length;
    const isValid = (interviewSlots.filter(slot => slot !== '').length > 0 && generalAvailability.trim() !== '<p><br></p>') && termsAccepted;
    setFormValid(isValid);

    localStorage.setItem(`applyJobInterviewData_${jobId}`, JSON.stringify({
      interviewSlots,
      generalAvailability,
      termsAccepted
    }));
  }, [interviewSlots, generalAvailability, termsAccepted, jobId]);

  const fetchExistingApplication = async () => {
    console.log('Fetching existing application...');
    try {
      const { data: sessionData, error: sessionError } = await supabase.auth.getSession();
      if (sessionError) throw sessionError;

      const accessToken = sessionData.session.access_token;

      const response = await fetch('/.netlify/functions/fetchExistingApplication', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({ jobId })
      });

      if (!response.ok) throw new Error('Failed to fetch existing application');

      const data = await response.json();
      if (data) {
        console.log('Existing application data:', data);
        setInterviewSlots(data.consultant_slots ? data.consultant_slots.map(slot => new Date(slot)) : ['']);
        setGeneralAvailability(data.consultant_general_availability || '');
        setTermsAccepted(data.terms_and_conditions || false);
        console.log('Application Status:', data.status);  // Log the status field
      } else {
        console.log('No existing application found');
      }
    } catch (error) {
      console.error('Error fetching existing application:', error.message);
    }
  };

  const fetchJobDetails = async () => {
    try {
      const { data: sessionData, error: sessionError } = await supabase.auth.getSession();
      if (sessionError) throw sessionError;

      const accessToken = sessionData.session.access_token;

      const response = await fetch('/.netlify/functions/fetchJobDetails', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({ jobId })
      });

      if (!response.ok) throw new Error('Failed to fetch job details');

      const data = await response.json();
      setJob(data);
    } catch (error) {
      console.error('Error fetching job details:', error.message);
    }
  };

  const fetchUser = async () => {
    try {
      const { data: sessionData, error: sessionError } = await supabase.auth.getSession();
      if (sessionError) throw sessionError;

      const accessToken = sessionData.session.access_token;

      const response = await fetch('/.netlify/functions/fetchUser', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });

      if (!response.ok) throw new Error('Failed to fetch user');

      const data = await response.json();
      setUser(data);
    } catch (error) {
      console.error('Error fetching user:', error.message);
    }
  };

  const handleAddSlot = () => {
    if (interviewSlots.length < 5) {
      setInterviewSlots([...interviewSlots, '']);
    }
  };

  const handleRemoveSlot = (index) => {
    const newSlots = interviewSlots.filter((_, i) => i !== index);
    setInterviewSlots(newSlots);
  };

  const handleSlotChange = (index, date) => {
    const newSlots = [...interviewSlots];
    if (date) {
      newSlots[index] = date;
    } else {
      newSlots[index] = '';
    }
    setInterviewSlots(newSlots);
  };

  const handleTermsAndConditions = async () => {
    try {
      const { data: sessionData, error: sessionError } = await supabase.auth.getSession();
      if (sessionError) throw sessionError;

      const accessToken = sessionData.session.access_token;

      const validSlots = interviewSlots.filter(slot => slot !== '').map(slot => {
        return new Date(slot).toISOString();
      });

      const upsertData = {
        jobId,
        consultant_slots: validSlots,
        consultant_general_availability: generalAvailability,
        terms_and_conditions: termsAccepted
      };

      const response = await fetch('/.netlify/functions/handleTermsAndConditions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(upsertData)
      });

      if (!response.ok) throw new Error('Failed to save to database');

      navigate(`/app/consultant-apply-terms-and-conditions/${jobId}?returnUrl=${encodeURIComponent(`/app/apply-job-interview/${jobId}`)}`);
    } catch (error) {
      console.error('Error saving to database:', error.message);
      alert(`Error saving to database: ${error.message}. Please try again.`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validSlotsData = interviewSlots.filter(slot => slot !== '').length;
    
    if (validSlotsData < 2) {
      setErrorMessage('Please choose at least two time slots for your interview.');
      return;
    }
    
    setErrorMessage('');

    if (!formValid) return;

    try {
      const { data: sessionData, error: sessionError } = await supabase.auth.getSession();
      if (sessionError) throw sessionError;

      const accessToken = sessionData.session.access_token;

      const validSlots = interviewSlots.filter(slot => slot !== '').map(slot => {
        return new Date(slot).toISOString();
      });

      const submitData = {
        jobId,
        consultant_slots: validSlots,
        consultant_general_availability: generalAvailability,
        terms_and_conditions: termsAccepted
      };

      const response = await fetch('/.netlify/functions/submitApplication', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(submitData)
      });

      if (!response.ok) throw new Error('Failed to submit application');

      localStorage.removeItem(`applyJobInterviewData_${jobId}`);
              
      const analytics = getAnalytics();
      logEvent(analytics, 'submit_application', {
        job_id: jobId,
        terms_accepted: termsAccepted,
      });

      alert('Applied! Your information has been shared with the client.');
      navigate('/app/consultant-jobs-applied');
    } catch (error) {
      console.error('Error submitting application:', error);
      alert(`Error submitting application: ${error.message || 'Unknown error'}. Please try again.`);
    }
  };

  return (
    <>
      <Helmet>
        <title>Apply for Job - Interview | fetchConsultant</title>
        <meta name="description" content="Schedule your interview for an Epic consulting position on fetchConsultant." />
        <meta name="keywords" content="Epic consulting, job interview, schedule interview, healthcare IT" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="noindex, nofollow" />
        <meta property="og:title" content="Apply for Job - Interview | fetchConsultant" />
        <meta property="og:description" content="Submit your hourly rate directly to the client." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://fetchconsultant.com/app/apply-job/:jobId" />
        <meta property="og:image" content="https://fetchconsultant.com/app/path-to-image.jpg" />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ConsultantHamburger user={user} currentPage="apply-job" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex items-center justify-between">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <ChevronLeft className="h-6 w-6 text-brandPrimary cursor-pointer" onClick={() => navigate(-1)} />
              <h1 className="text-xl font-bold ml-4">Apply for Job - Interview</h1>
            </div>
            <div className="flex items-center">
              <NotificationBell />
            </div>
          </header>
          <main className="fetch-grow container mx-auto px-4 py-8">
            <div className="fetch-card">
              <div className="mb-6">
                <p className="fetch-secondary-text">Step 2 of 2</p>
                <div className="fetch-progress-bar">
                  <div className="fetch-progress-bar-fill" style={{width: '100%'}}></div>
                </div>
              </div>
              <form onSubmit={handleSubmit} className="fetch-form">
                {errorMessage && (
                  <div className="mb-4 text-red-600 font-bold">{errorMessage}</div>
                )}
              <div>
                <label className="fetch-label mb-4">Please choose a few times you are available to interview for this position. All slots are for one hour.<span className="fetch-required">*</span></label>
                {interviewSlots.map((slot, index) => (
                  <div key={index} className="mb-4 flex flex-col items-start w-full">
                    <label htmlFor={`slot-${index}`} className="fetch-label mb-2">Slot {index + 1}</label>
                    <div className="w-full flex items-center">
                      <div className="w-full">
                      <DatePicker
                        id={`slot-${index}`}
                        selected={slot ? new Date(slot) : null}
                        onChange={(date) => handleSlotChange(index, date)}
                        showTimeSelect
                        timeIntervals={30}
                        timeFormat="h:mm aa"
                        timeCaption="Time"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        className="fetch-input w-full date-picker-custom"
                        placeholderText="Select date and time"
                        popperClassName="custom-popper date-picker-popper"
                        calendarClassName="custom-calendar"
                        dayClassName={() => "custom-day"}
                        timeClassName={() => "custom-time"}
                        wrapperClassName="date-picker-wrapper"
                      />
                      </div>
                      {index > 0 && (
                        <button
                          type="button"
                          onClick={() => handleRemoveSlot(index)}
                          className="text-red-600 ml-2"
                          aria-label="Remove slot"
                        >
                          <X size={20} />
                        </button>
                      )}
                    </div>
                  </div>
                ))}
                {interviewSlots.length < 5 && (
                  <button type="button" onClick={handleAddSlot} className="fetch-button bg-gray-200 text-gray-700 mt-4">
                    <Plus className="inline-block mr-2" size={20} />
                    Add Slot
                  </button>
                )}
              </div>
              <div className="mb-4">
                <label htmlFor="consultant_general_availability" className="fetch-label">
                    Describe your general availability if none of the above times work for the client.<span className="fetch-required">*</span></label>
                <ReactQuill
                  theme="snow"
                  value={generalAvailability}
                  onChange={setGeneralAvailability}
                  className="fetch-input"
                  modules={{
                    toolbar: [
                      ['bold', 'italic', 'underline'],
                      [{'list': 'ordered'}, {'list': 'bullet'}],
                      ['link'],
                    ],
                  }}
                />
              </div>
              <div className="mt-6">
                <h2 className="text-xl font-bold mb-2">Terms and Conditions</h2>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="terms_and_conditions"
                    name="terms_and_conditions"
                    checked={termsAccepted}
                    onChange={(e) => setTermsAccepted(e.target.checked)}
                    className="fetch-checkbox"
                    required
                    disabled={true}
                  />
                  <label htmlFor="terms_and_conditions" className="fetch-checkbox-label">
                    I have read and agree to the <button type="button" onClick={handleTermsAndConditions} className="text-brandPrimary hover:text-indigo-800">terms and conditions</button>.<span className="fetch-required">*</span>
                  </label>
                </div>
              </div>

              <div className="mt-6">
                <button
                  type="submit"
                  className={`fetch-button ${!formValid ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={!formValid}
                >
                  Submit Application
                </button>
              </div>
            </form>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default ApplyJobInterview;